import React from "react";
import "../styles/content.css";

const Content = props => {
  return (
    <div className="content-container">
      <div className="medium-style-content">{props.children}</div>
    </div>
  );
};

export default Content;
