import React from "react";
import Content from "./Content";
import me from "../images/me.png";

const About = () => {
  return (
    <Content>
      <img src={me} style={{ maxHeight: "40%", maxWidth: "40%" }} alt="Me" />
      <h2>About me</h2>
      <p>
        I&#39;m a self-taught textile artist based in Sheffield. I have always
        enjoyed experimenting with fabrics, stitches, and colours. I love the
        way that different materials and stitches can be combined to create
        texture and detail, and how colours can be used to create mood and
        atmosphere.
      </p>
      <h3>Getting inspiration</h3>
      My work is largely inspired by the beauty of the natural world, and
      wherever I go I am always on the lookout for new ways to represent this in
      my work. Living in Sheffield, on the Yorkshire/Derbyshire border, the
      stunning scenery is a constant source of inspiration. From the rolling
      hills of the Peak District, the quaint Derbyshire villages, or the
      city&#39;s parks and gardens. I find inspiration in the delicate shapes
      and patterns of flowers and plants and in the ever changing landscapes
      that surround me.
      <h3>My technique</h3>I use free-motion machine embroidery and appliqué to
      create my artwork. Free-motion machine embroidery is like drawing with
      stitches. I use a sewing machine to create intricate and detailed freehand
      patterns, pictures and designs. Appliqué involves sewing other pieces of
      fabric onto the base fabric to add dimension, colour and texture. It’s
      also a great way to incorporate upcycled pieces of fabric into my work.
      <h3>My process</h3>
      <p>
        The process begins with something that captures my imagination and
        inspires me to want to recreate or depict it in my artwork. I then begin
        the process of selecting which materials to use. I will select different
        colours, shades, patterns, textures and types of fabric, threads, beads
        and anything else which I feel will best represent my ideas.
      </p>
      Next I sketch out my design and plan how to incorporate, arrange and shape
      the materials I’ve selected and the stitching techniques required in the
      piece.
      <p>
        Finally I begin to create my design, using a mixture of free-motion
        machine embroidery, hand embroidery and appliqué. I often experiment
        with different techniques and materials until I find the combination
        that I feel works best.
      </p>
      <p>
        I believe that art can be a powerful tool for communication and
        expression. I hope that my work will inspire others to appreciate the
        beauty of nature and to find their own creative voice.
      </p>
    </Content>
  );
};

export default About;
