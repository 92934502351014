import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Content from "./Content";
import { Gallery } from "react-grid-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

// Image imports
import autumn from "../images/autumn.jpg";
import autumn_fields from "../images/autumn_fields.jpg";
import daffodils from "../images/daffodils.jpg";
import daisies from "../images/daisies.jpg";
import derbyshire_landscape from "../images/derbyshire_landscape.jpg";
import fading_light from "../images/fading_light.jpg";
import shades_of_autumn from "../images/shades_of_autumn.jpg";
import snowdrops from "../images/snowdrops.jpg";
import summer_day from "../images/summer_day.jpg";
import wildflower_bay from "../images/wildflower_bay.jpg";
import winter_blues from "../images/winter_blues.jpg";
import fuchsias from "../images/fuchsias.jpg";
import orange_vase from "../images/orange_vase.jpg";
import seedhead_sunset from "../images/seedhead_sunset.jpg";
import seedheads from "../images/seedheads.jpg";
import peak_poppies from "../images/peak_poppies.jpg";
import pinks_and_blues from "../images/pinks_blues.jpg";
import seashore from "../images/seashore.jpg";
import hope_valley from "../images/hope_valley.jpg";
import moorland_heather from "../images/moorland_heather.jpg";
import summer_meadow from "../images/summer_meadow.jpeg";
import oranges_and_pinks from "../images/oranges_and_pinks.jpeg";
import blues_and_gold from "../images/blues_and_gold.jpeg";
import autumn_in_the_peak from "../images/autumn_in_the_peak.jpeg";


const photos = [
  {
    src: summer_meadow,
    index: 0,
    caption: "Summer meadow"
  },
  {
    src: oranges_and_pinks,
    index: 1,
    caption: "Oranges and pinks"
  },
  {
    src: blues_and_gold,
    index: 2,
    caption: "Blues and gold"
  },
  {
    src: autumn_in_the_peak,
    index: 3,
    caption: "Autumn in the peak"
  },
  {
    src: autumn,
    index: 4,
    caption: "Autumn"
  },
  {
    src: daffodils,
    index: 5,
    caption: "Daffodils"
  },
  {
    src: daisies,
    index: 6,
    caption: "Daisies"
  },
  {
    src: derbyshire_landscape,
    index: 7,
    caption: "Derbyshire landscape"
  },
  {
    src: fading_light,
    index: 8,
    caption: "Fading light"
  },
  {
    src: shades_of_autumn,
    index: 9,
    caption: "Shades of autumn"
  },
  {
    src: snowdrops,
    index: 10,
    caption: "Snowdrops"
  },
  {
    src: summer_day,
    index: 11,
    caption: "Summer day"
  },
  {
    src: wildflower_bay,
    index: 12,
    caption: "Wildflower bay"
  },
  {
    src: winter_blues,
    index: 13,
    caption: "Winter blues"
  },
  { src: fuchsias, index: 14, caption: "Fuchsias" },
  { src: orange_vase, index: 15, caption: "Orange vase" },
  {
    src: seedhead_sunset,
    index: 16,
    caption: "Seedhead sunset"
  },
  { src: seedheads, index: 17, caption: "Seedheads" },
  { src: peak_poppies, index: 18, caption: "Peak Poppies" },
  { src: pinks_and_blues, index: 19, caption: "Pinks and Blues" },
  { src: seashore, index: 20, caption: "Seashore" },
  { src: hope_valley, index: 21, caption: "Hope Valley" },
  { src: moorland_heather, index: 22, caption: "Moorland Heather" },
  {
    src: autumn_fields,
    index: 23,
    caption: "Autumn fields"
  },
];

const Works = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Content>
        <h2>Examples of my work</h2>
        <p>
          These are just a few examples to illustrate the kind of work I do. You
          can{" "}
          <Link to={"https://www.instagram.com/kath_priestley_designs/"}>
            see my latest projects on Instagram
          </Link>
          .
        </p>
        <p>
          If you're interested in seeing the original pieces I currently have
          for sale, you can{" "}
          <Link to={"https://www.etsy.com/shop/KathPriestleyDesigns"}>
            find them on my Etsy shop.
          </Link>
        </p>
        <p>Click on an image to see it in more detail.</p>
        <Gallery
          images={photos}
          onClick={openLightbox}
          enableImageSelection={false}
        />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.caption
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Content>
    </div>
  );
};

export default Works;
