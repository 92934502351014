import React from "react";
import Content from "./Content";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <Content>
      <h2>Get in touch</h2>
      <p>
        If you would like to learn more about my work or commission a piece,
        please contact me at kathpriestley@gmail.com
      </p>
      <p>
        To see my latest work,{" "}
        <Link to={"https://www.instagram.com/kath_priestley_designs/"}>
          follow me on Instagram
        </Link>
        .
      </p>
      <p>
        You can buy originals, prints, and cards on{" "}
        <Link to={"https://www.etsy.com/shop/KathPriestleyDesigns"}>
          my Etsy shop
        </Link>
        .
      </p>
    </Content>
  );
};

export default Contact;
