import React from "react";
import Content from "./Content";

const Events = () => {
  return (
    <Content>
      <h2>Events and workshops</h2>
      <h3>October 2023</h3>
      <p>
        Dronfield Hall Barn Artisan Market and Craft Fair - Sunday 8th October
      </p>
      <p>Helmsley Craft Fair - Sunday 15th October</p>
      <h3>November 2023</h3>
      <p>Southwell Minster Winter Craft Fair - Friday 3rd and Saturday 4th</p>
      <p>
        November Dronfield Hall Barn Artisan Market and Craft Fair - Friday 24th
        and Saturday 25th November
      </p>
      <h3>December 2023</h3>
      <p>
        Ruddington Village Christmas Market - Friday 1st and Saturday 2nd
        December
      </p>
    </Content>
  );
};

export default Events;
